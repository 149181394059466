import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ApiTest from './apitest';
import ChatPage from './components/ChatPage';

function App() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <Router>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/chat" replace />}
            />
            <Route
              path="/chat"
              element={<ChatPage user={user} signOut={signOut} />}
            />
            {/* Add more routes here as needed */}
          </Routes>
        </Router>
      )}
    </Authenticator>
  );
}

export default App;
