import React, { useState, useEffect, useRef } from 'react';
import { get, post } from 'aws-amplify/api';
import { Hub } from 'aws-amplify';
import './ChatPage.css';

const ChatPage = ({ user, signOut }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);

  // Fetch messages when the component mounts
  useEffect(() => {
    fetchMessages();

    // Optionally, set up real-time updates using polling
    const interval = setInterval(() => {
      fetchMessages();
    }, 3000); // Fetch every 3 seconds

    return () => clearInterval(interval);
  }, []);

  // Scroll to the newest message
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const fetchMessages = async () => {
    try {
      const response = await get({
        apiName: 'sovatt',
        path: '/chat/messages',
      });

      if (response && response.body) {
        const responseData = await response.body.json();
        setMessages(responseData.messages);
      }
    } catch (err) {
      console.error('Error fetching messages:', err);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const newMessage = {
      sender: user.username,
      content: input,
      timestamp: new Date().toISOString(),
    };

    try {
      await post({
        apiName: 'sovatt',
        path: '/chat/messages',
        body: newMessage,
      });
      setInput('');
      fetchMessages(); // Refresh messages after sending
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  return (
    <div className="chat-container">
      <header className="chat-header">
        <h2>Welcome, {user.username}</h2>
        <button onClick={signOut} className="signout-button">
          Sign Out
        </button>
      </header>

      <div className="messages-container">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${
              msg.sender === user.username ? 'own-message' : 'other-message'
            }`}
          >
            <div className="message-sender">{msg.sender}</div>
            <div className="message-content">{msg.content}</div>
            <div className="message-timestamp">
              {new Date(msg.timestamp).toLocaleTimeString()}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <form className="message-input-container" onSubmit={sendMessage}>
        <input
          type="text"
          className="message-input"
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit" className="send-button">
          Send
        </button>
      </form>
    </div>
  );
};

export default ChatPage; 